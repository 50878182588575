/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { PresupuestoContext } from '../contexts/PresupuestoContext'
import moment from 'moment'

import PresupuestoForm from './PresupuestoForm'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { Dropdown } from 'primereact/dropdown'
import { DataPresupuestoContext } from '../contexts/DataPresupuestoContext'

const PresupuestoList = () => {
  const { proyectos } = useContext(ProyectoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const {
    updatePresupuesto,
    presupuestos,
    findPresupuesto,
    deletePresupuesto,
    loading
  } = useContext(PresupuestoContext)
  const { dataPresupuestos } = useContext(DataPresupuestoContext)
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)
  const [loadingProyecto, setoLadingProyecto] = useState(true)
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [presupuesto, setPresupuesto] = useState(presupuestos)
  const [presupuestoDelete, setPresupuestoDelete] = useState(null)

  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [deleteBarcoDialog, setDeleteBarcoDialog] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [isVisible, setIsVisible] = useState(false)

  const [selectedProducts, setSelectedProducts] = useState(null)
  const dt = useRef(null)
  const toast = useRef(null)
  const saveBarco = (id) => {
    findPresupuesto(id)
    setIsVisible(true)
  }
  useEffect(() => {
    if (selectedSubProyecto) {
      const presupuestosFilter = presupuestos.filter(
        (p) => p.subProyectoId?.id === selectedSubProyecto.id
      )
      setPresupuesto(presupuestosFilter)
      setoLadingProyecto(false)
    }
    // setDataPresupuesto(dataPresupuestos)
  }, [presupuestos])
  useEffect(() => {
    const updateTotals = async () => {
      const totals = []
      for (const presupuestoTotal of presupuestos) {
        let total = 0
        for (const dataPresupuestoTotal of dataPresupuestos) {
          if (presupuestoTotal.id === dataPresupuestoTotal.presupuestoId?.id) {
            if (dataPresupuestoTotal.estatusDataPresupuesto !== 'ANULADO') {
              total +=
                dataPresupuestoTotal.cantidadDataPresupuesto *
                dataPresupuestoTotal.precioUnitarioDataPresupuesto
            }
          }
        }
        totals.push({ id: presupuestoTotal.id, total })
      }

      for (const t of totals) {
        await updatePresupuesto({
          id: t.id,
          totalPresupuesto: t.total
        })
      }
    }

    updateTotals()
  }, [dataPresupuestos])

  const onSubProyecto = (e) => {
    if (e.value) {
      const presupuestosFilter = presupuestos.filter(
        (p) => p.subProyectoId?.id === e.value.id
      )
      setSelectedSubProyecto(e.value)
      setPresupuesto(presupuestosFilter)
      setoLadingProyecto(false)
    } else {
      setSelectedSubProyecto(null)
      setoLadingProyecto(true)
      setPresupuesto(subProyectos)
    }
  }
  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)
      setSubProyecto(subProyectoFilter)
    } else {
      setSelectedProyecto(null)
    }
  }
  // cabecera de la tabla
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Dropdown
          value={selectedProyecto}
          options={proyectos}
          onChange={onProyecto}
          optionLabel="nombreProyecto"
          placeholder="Seleccione Proyecto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombrePresupuesto"
        />
        <Dropdown
          value={selectedSubProyecto}
          options={subProyecto}
          onChange={onSubProyecto}
          optionLabel="nombreSubProyecto"
          placeholder="Seleccione Concepto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombrePresupuesto"
          disabled={!selectedProyecto}
        />
        <div className="my-2 ">
          <Button
            label="Nuevo"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => setIsVisible(true)}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
          />
        </div>
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    )
  }
  const exportCSV = () => {
    dt.current.exportCSV()
  }
  const fechaAtracoTemplate = (rowData) => {
    const validarFecha = moment(rowData.fechaAtraco).isValid()
    if (!validarFecha) return
    const fecha = moment(rowData.fechaAtraco)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechaInicioCargaTemplate = (rowData) => {
    const validarFecha = moment(rowData.fechaInicioCarga).isValid()
    if (!validarFecha) return
    const fecha = moment(rowData.fechaInicioCarga)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechaFinalCargaTemplate = (rowData) => {
    const validarFecha = moment(rowData.fechaFinalCarga).isValid()
    if (!validarFecha) return
    const fecha = moment(rowData.fechaFinalCarga)
    return fecha.format('dddDD/MM/YY HH:mm')
  }

  const fechacreadoPresupuesto = (rowData) => {
    const fecha = moment(rowData.creadoPresupuesto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechamodificadoPresupuesto = (rowData) => {
    const fecha = moment(rowData.modificadoPresupuesto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechaInicioPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaInicioPresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const fechaFinalPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaFinalPresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const fechaInicioPlanPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaInicioPlanPresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const fechaFinalPlanPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaFinalPlanPresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const fechaInicioRealPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaInicioRealPresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const fechaFinalRealPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaFinalRealPresupuesto)
    return fecha.format('dddDD/MM/YY')
  }

  const eliminarBarco = () => {
    deletePresupuesto(presupuestoDelete.id)
    setDeleteBarcoDialog(false)
    toast.current.show({
      severity: 'error',
      summary: 'Eliminar',
      detail: 'Barco Eliminado',
      life: 3000
    })
  }

  const deleteBarcoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteBarcoDialog(false)}
      />
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => eliminarBarco()}
      />
    </>
  )

  const confirmDeleteBarco = (barcos) => {
    setPresupuestoDelete(barcos)
    setDeleteBarcoDialog(true)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2 mb-2"
          onClick={() => saveBarco(rowData.id)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded  p-button-danger"
          onClick={() => confirmDeleteBarco(rowData)}
        />
      </div>
    )
  }
  const diasTotalesCarga = (rowData) => {
    function secondsToString(diff) {
      const numdays = Math.floor(diff / 86400)
      const numhours = Math.floor((diff % 86400) / 3600)
      const numminutes = Math.floor(((diff % 86400) % 3600) / 60)
      // const numseconds = ((diff % 86400) % 3600) % 60

      return (
        numdays + ' dias ' + numhours + ' horas ' + numminutes + ' minutos '
      )
    }
    const fecha1 = moment(rowData.fechaInicioCarga)
    const fecha2 = moment(
      rowData.fechaFinalCarga ? rowData.fechaFinalCarga : moment()
    ) //
    const diff = fecha2.diff(fecha1, 'seconds') // Diff in days

    return <div className="actions">{secondsToString(diff)}</div>
  }
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={
          rowData.image
            ? rowData.image.url
            : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
        }
        onError={(e) =>
          (e.target.src =
            'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png')
        }
        alt={rowData.image}
        className="product-image"
        height="100px"
        width={'100px'}
      />
    )
  }
  const imageFinalBodyTemplate = (rowData) => {
    return (
      <img
        src={
          rowData.imageFinal
            ? rowData.imageFinal.url
            : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
        }
        onError={(e) =>
          (e.target.src =
            'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png')
        }
        alt={rowData.imageFinal}
        className="product-image"
        height="100px"
        width={'100px'}
      />
    )
  }
  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Presupuesto</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )
  const clearSelected = () => {
    setDeleteBarcoDialog(false)
  }
  return (
    <>
      <Toast ref={toast} />
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <DataTable
        ref={dt}
        value={presupuesto}
        onSelectionChange={(e) => setSelectedProducts(e.value_id)}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Muestra {first} a {last} de {totalRecords} Presupuesto"
        globalFilter={globalFilter}
        emptyMessage="No hay datos."
        header={header}
        sortField="barcoCreado"
        sortOrder={-1}
        // loading={loadingProyecto}
        responsiveLayout="scroll"
        breakpoint="960px"
      >
        <Column body={actionBodyTemplate}></Column>
        {/* <Column
          field="subProyectoId.nombreSubProyecto"
          header="subProyectoId.nombreSubProyecto"
        /> */}
        <Column field="nombrePresupuesto" header="Nombre" />
        <Column field="descripcionPresupuesto" header="Descripción" />
        <Column field="totalPresupuesto" header="Total" />
        <Column field="totalValuacionPresupuesto" header="Total Valuacion" />

        <Column field="avancePresupuesto" header="Avance" />
        <Column
          field="fechaInicioPresupuesto"
          header="Inicio Automatica"
          body={fechaInicioPresupuesto}
          dataType="date"
        />
        <Column field="totalPlanPresupuesto" header="Total Plan" />
        <Column field="totalRealPresupuesto" header="Total Real" />
        <Column
          field="fechaFinalPresupuesto"
          header="Final Automatica"
          body={fechaFinalPresupuesto}
          dataType="date"
        />
        <Column
          field="fechaInicioPlanPresupuesto"
          header="Inicio"
          body={fechaInicioPlanPresupuesto}
          dataType="date"
        />
        <Column
          field="fechaFinalPlanPresupuesto"
          header="Final"
          body={fechaFinalPlanPresupuesto}
          dataType="date"
        />
        <Column
          field="fechaInicioRealPresupuesto"
          header="Inicio"
          body={fechaInicioRealPresupuesto}
          dataType="date"
        />
        <Column
          field="fechaFinalRealPresupuesto"
          header="Final"
          body={fechaFinalRealPresupuesto}
          dataType="date"
        />
        <Column field="image" header="Image" body={imageBodyTemplate} />
        <Column
          field="imageFinal"
          header="imageFinal"
          body={imageFinalBodyTemplate}
        />
        <Column field="prioridadPresupuesto" header="Prioridad" />
        <Column field="estatusPresupuesto" header="Estatus" />
        <Column
          field="creadoPresupuesto"
          body={fechacreadoPresupuesto}
          dataType="date"
          header="Creado"
        />
        <Column
          field="modificadoPresupuesto"
          body={fechamodificadoPresupuesto}
          header="Modificado"
          dataType="date"
        />
      </DataTable>

      <PresupuestoForm isVisible={isVisible} setIsVisible={setIsVisible} />

      <Dialog
        visible={deleteBarcoDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={deleteBarcoDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {presupuestoDelete && (
            <span>
              Esta seguro que quiere eliminar la barco{' '}
              <b>{presupuestoDelete.nombrePresupuesto}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default PresupuestoList
